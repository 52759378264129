// Webpack Polyfill Injector
function main() {
    require("./src/ds/index.js");
}
var polyfills = function() {
    return [
        /* Array.prototype.filter */ ("filter"in Array.prototype
) ? 0 : 1,
        /* Array.prototype.findIndex */ ("findIndex"in Array.prototype
) ? 0 : 1,
        /* Object.values */ ("values"in Object
) ? 0 : 1,
        /* Promise */ ("Promise"in self
) ? 0 : 1,
        /* String.prototype.endsWith */ ("endsWith"in String.prototype
) ? 0 : 1,
        /* String.prototype.includes */ ("includes"in String.prototype
) ? 0 : 1,
        /* fetch */ ("fetch"in self&&"Request"in self&&function(){try{return"signal"in new Request("")}catch(e){return!1}}()
) ? 0 : 1
    ];
}.call(window);
if (polyfills.indexOf(1) === -1) {
    main();
} else {
    var js = document.createElement('script');
    js.src = "/polyfills.5ecd3369cdb391f75a87." + polyfills.join('') + '.js';
    js.onload = main;
    js.onerror = function onError(message) {
        console.error('Could not load the polyfills: ' + message);
    };
    document.head.appendChild(js);
}