import '../assets/ds.scss';


import { dom, library, config } from '@fortawesome/fontawesome-svg-core';
import {faPlusSquare} from '@fortawesome/free-solid-svg-icons/faPlusSquare';
import {faPen} from '@fortawesome/free-solid-svg-icons/faPen';
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons/faAngleRight';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';

config.autoReplaceSvg = 'nest';

library.add(faPlusSquare, faPen, faSearch, faAngleRight, faTimes);
dom.watch();

import * as $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'jquery-ui/ui/widget.js';

const Hogan = require("hogan.js");

//import '@theidentityselector/thiss-jquery-plugin/src/ds-widget.js';
import {PersistenceService} from "@theidentityselector/thiss-ds";
import {DiscoveryService, parse_qs, json_mdq_search} from "@theidentityselector/thiss-ds";
require("./bootstrap-list-filter.src.js");
require("./ds-widget.js");
// const learn_more_url = process.env.LEARN_MORE_URL || "https://seamlessaccess.org/about/trust/";
const search = Hogan.compile(require('!raw-loader!./templates/search.html'));
const saved = Hogan.compile(require('!raw-loader!./templates/saved.html'));
const too_many = Hogan.compile(require('!raw-loader!./templates/too_many.html'));
const no_results = Hogan.compile(require('!raw-loader!./templates/no_results.html'));
const service_logo = Hogan.compile(require('!raw-loader!./templates/service_logo.html'));
const footer = Hogan.compile(require('!raw-loader!./templates/footer.html'));
const custom_footer = Hogan.compile(require('!raw-loader!./templates/custom_footer.html'));
const ribbon = Hogan.compile(require('!raw-loader!./templates/ribbon.html'));
// const learnMoreBanner = Hogan.compile(require('!raw-loader!./templates/learn_more_banner.html'));
// const noticeAndConsentActions = Hogan.compile(require('!raw-loader!./templates/notice_and_consent_actions.html'));
const search_input = document.querySelector("#searchinput");
const search_icon = document.querySelector("#searchinput + i");
const buttons = Hogan.compile(require('!raw-loader!./templates/buttons.html'));
const disclaimer = Hogan.compile(require('!raw-loader!./templates/disclaimer.html'));
const examples = Hogan.compile(require('!raw-loader!./templates/examples.html'));
const login_chosen = Hogan.compile(require('!raw-loader!./templates/login_chosen.html'));
const login_choose = Hogan.compile(require('!raw-loader!./templates/login_choose.html'));
const shortlist_switch = Hogan.compile(require('!raw-loader!./templates/shortlist.html'))

function handle_search_input_change(e) {
    search_icon.hidden = Boolean(e.target.value);
}
search_input.addEventListener('input', handle_search_input_change);

function prepare_custom_footer() {
    function update_footer_height() {
        const footer_contents = document.querySelector(".custom-footer")?.contentDocument;
        if (footer_contents) {
            const footer_height = footer_contents.documentElement.offsetHeight;
            const footer_container = document.querySelector(".custom-footer-container");
            footer_container.style.height = footer_height + "px";
        }
    }

    function set_footer_link_targets() {
        const footer_contents = document.querySelector(".custom-footer")?.contentDocument;
        if (footer_contents) {
            const footer_links = [...footer_contents.getElementsByTagName("a")];
            footer_links.forEach(el => el.setAttribute("target", "_blank"));
        }
    }

    document.querySelector(".custom-footer").addEventListener("load", () => {
        update_footer_height();
        set_footer_link_targets();
    });
    window.addEventListener("resize", update_footer_height);
}

function customize_ui() {
    const data = {
        service_name: process.env.SERVICE_NAME,
        show_eduteams_by: (process.env.SHOW_EDUTEAMS_BY.toString().toLowerCase() == 'true'),
        dev_ribbon: process.env.DEV_RIBBON,
        shortlist_url: process.env.SHORTLIST_URL,
        service_logo: process.env.SERVICE_LOGO,
        service_logo_alt_text: process.env.SERVICE_LOGO_ALT_TEXT,
        support_link: process.env.SUPPORT_LINK,
        support_link_text: process.env.SUPPORT_LINK_TEXT,
        no_results_text: process.env.NO_RESULTS_TEXT,
        organization_logo: process.env.ORGANIZATION_LOGO,
        organization_name: process.env.ORGANIZATION_NAME,
        privacy_policy_url: process.env.PRIVACY_POLICY_URL,
        disco_ui_idp_shortcuts_raw: process.env.DISCO_UI_IDP_SHORTCUTS,
        disclaimer_logo: process.env.DISCLAIMER_LOGO,
        disclaimer_title: process.env.DISCLAIMER_TITLE,
        disclaimer_text: process.env.DISCLAIMER_TEXT,
        disclaimer: process.env.DISCLAIMER.toString().toLowerCase(),
        guide: process.env.GUIDE.toString().toLowerCase(),
        examples: process.env.EXAMPLES,
        login_chosen: process.env.LOGIN_CHOSEN,
        login_choose: process.env.LOGIN_CHOOSE,
        footer_file_url: process.env.FOOTER_FILE_URL
    }
    data.disco_ui_idp_shortcuts = JSON.parse(data.disco_ui_idp_shortcuts_raw)
    document.getElementById("eduteams_logo").innerHTML = service_logo.render(data);
    document.getElementById("eduteams_footer").innerHTML = footer.render(data);
    document.getElementById("login_chosen").innerHTML = login_chosen.render(data);
    document.getElementById("login_choose").innerHTML = login_choose.render(data);
    document.getElementById("searchinput").placeholder = process.env.EXAMPLES

    if (data.footer_file_url) {
        document.querySelector("footer").outerHTML = custom_footer.render(data);
        prepare_custom_footer();
    } else {
        document.getElementById("eduteams_footer").innerHTML = footer.render(data);
    }

    if (data.shortlist_url) {
        document.getElementById("shortlist_switch").innerHTML = shortlist_switch.render(data)
        const shortlist_checkbox = document.body.querySelector('#ExtendedListSwitchBox');
        shortlist_checkbox.onchange = (e => search_input.dispatchEvent(new Event('keyup')));
    }

    if (data.disco_ui_idp_shortcuts.length) {
        document.getElementById("eduteams_buttons").innerHTML = buttons.render(data);
    }
    if (data.disclaimer == 'true') {
        document.getElementById("disclaimer-section").innerHTML = disclaimer.render(data);
    }
    if (data.guide == 'false') {
        document.getElementById("guide").hidden = true;
    }
    if (data.dev_ribbon) {
        document.getElementById("eduteams_ribbon").innerHTML = ribbon.render(data);
    }
    if (process.env.SHOW_BUTTONS_IN_ROW.toString().toLowerCase() == 'true') {
        document.querySelectorAll('.idps')
            .forEach(element => {
                element.style.flexDirection = 'row';
            });
        document.querySelectorAll('p.idp-text.idp-label')
            .forEach(element => {
                element.style.display = 'none';
            });
        document.querySelectorAll('.idp-box')
            .forEach(element => {
                element.style.padding = '0.4rem';
            });
    }
}


$(document).ready(function() {
    customize_ui();
    let timer = null;

    // $('#notice-and-consent-actions').html(noticeAndConsentActions.render({}));
    // $('#learn-more-banner').html(learnMoreBanner.render({learn_more_url: learn_more_url}));

    // $('#learn-more-trigger, #learn-more-close').on('click', function() {
    //   $("#learn-more-banner").toggleClass("d-none");
    // })

    $("#search").on('hidden.bs.collapse',function(event) {
        $("#choose").toggleClass("d-none");
        $("#search").toggleClass("d-none");
        $("#searchinput").val('');
    }).on('shown.bs.collapse',function(event) {
        $("#choose").toggleClass("d-none");
        $("#search").toggleClass("d-none");
        $("#searchinput").focus();
    });

    $("#ds-search-list").on('show.bs', function(event) {
        timer = setTimeout( function () { if (timer) { $("#searching").show(); } }, 500);
    }).on('hide.bs', function(event) {
        $("#searching").hide();
        if (timer) {
            clearTimeout(timer);
        }
    });

    $("#add_button").on('click',function(event) {
        event.preventDefault();
        $("#choose").toggleClass("d-none");
        $("#search").toggleClass("d-none");
    });

    $("#edit_button").on('click',function(event) {
        event.preventDefault();
        $("#choosetools").toggleClass("d-none");
        $("#done_button").toggleClass("d-none").toggleClass("display-block");
        $("#savedchoices").removeClass('choose').addClass('edit');
        $(".institution-text").addClass("item-fade");
        $(".institution-icon").addClass("item-fade");
        $(".institution-select").toggleClass("d-none");
        $(".institution-remove").toggleClass("d-none");
    });

    $("#done_button").on('click',function(event) {
        event.preventDefault();
        $("#done_button").toggleClass("d-none").toggleClass("display-block");
        $("#choosetools").toggleClass("d-none");
        $("#savedchoices").removeClass('edit').addClass('choose');
        $(".institution-text").removeClass("item-fade");
        $(".institution-icon").removeClass("item-fade");
        $(".institution-select").toggleClass("d-none");
        $(".institution-remove").toggleClass("d-none");
    });

    const return_urls = JSON.parse(process.env.RETURN_URLS);
    $("#dsclient").discovery_client({
        mdq: process.env.MDQ_URL,
        persistence: process.env.PERSISTENCE_URL,
        search: process.env.SEARCH_URL,
        context: process.env.DEFAULT_CONTEXT,
        return_urls: return_urls,
        inputfieldselector: "#searchinput",
        render_search_result: function(item) {
            $("#searching").hide();
            if (timer) {
                clearTimeout(timer); timer = null;
            }
            return search.render(item);
        },
        render_saved_choice: function(item) {
            return saved.render(item);
        },
        too_many_results: function(bts, count) {
            if (timer) {
                clearTimeout(timer); timer = null;
            }
            $("#searching").hide();
            let too_many_node = too_many.render({"count": count});
            $('body').on('click', "#showall", function() {  bts.showall() })
            return too_many_node;
        },
        no_results: function() {
            if (timer) {
                clearTimeout(timer); timer = null;
            }
            $("#searching").hide();
            const no_result_data = {
                no_results_text: process.env.NO_RESULTS_TEXT
            }
            return no_results.render(no_result_data);
        },
        // persist: function() {
        //     console.log($("#rememberThisChoice").is(':checked'));
        //     return $("#rememberThisChoice").is(':checked');
        // },
        after: function(count,elt) {
            console.log("after - "+count);
            $("#searching").hide();
            if (count == 0) {
                $("#search").removeClass("d-none");
                $("#choose").addClass("d-none");
                $("#searchinput").focus();
            } else {
                $("#choose").removeClass("d-none");
                $("#search").addClass("d-none");
            }
        }
    }).discovery_client("sp").then(entity => $(".sp_title").text(entity.title))
});
